
.benefits {
    &>div:nth-child(1) .cs-banner {
      @apply bg-yl-primary;
      border: 4px solid #fdf0e1;
    }

    &>div:nth-child(2) {
      @media (min-width: 1024px) {
        position: relative;
        padding: 0 32px;

        &::after {
          content: '';
          width: 42px;
          height: 0px;
          border-top: 1px solid #ebebeb;
          transform: rotate(90deg);
          position: absolute;
          right: -21px;
          top: 21px;
        }

        &::before {
          content: '';
          width: 42px;
          height: 0px;
          border-top: 1px solid #ebebeb;
          transform: rotate(90deg);
          position: absolute;
          left: -21px;
          top: 21px;
        }
      }

      .cs-banner {
        @apply bg-blue-secondary;
        border: 4px solid #eaf5fb;
      }
    }

    &>div:nth-child(3) .cs-banner {
      @apply bg-green-secondary;
      border: 4px solid #eef7e7;
    }
  }
