.overlay {
  z-index: 1040;
  &:before {
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    position: absolute;
    background-color: theme('colors.white');
    opacity: .75;
  }
}
