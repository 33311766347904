.expert {
  &-bubble {
    &:before {
      content: "";
      width: 35px;
      height: 35px;
      background: #feebc8;
      right: 98%;
      position: absolute;
      border-radius: 10px;
      transform: rotate(45deg);
    }
  }
  &-dark {
    background: rgba(0, 0, 0, .5);
  }
}
