.search-footer-dropdown {
  .block-content {
    margin: 1.143rem 0px 0px;
  }

  .block-ul-content {
    margin: 0;
  }

  .block-url {
    color: rgb(0, 0, 238);
    .text-body{
      color:  rgb(0, 0, 238);
    }
  }

  .block-content,
  .block-ul-content,
  .block-url,
  .text-body {
    a {
      text-decoration: underline;

      h3,h1 {
        text-decoration: inherit;
      }
    }
  }

  .font-bold{
    font-weight: 700 !important;
  }

  .tw-m-0 {
    margin: 0 !important;
  }

  .list-style-disc {
    list-style-type: disc;
  }

  .list-style-decimal {
   list-style-type: decimal;
  }

  .text-body {
    font-size: 14px;
    line-height: 1.714;
    font-weight: 400;
    letter-spacing: -0.005em;
    font-family: -apple-system, system-ui, "Segoe UI", Roboto, "Noto Sans", Ubuntu, "Droid Sans", "Helvetica Neue", sans-serif;
    color: rgb(23, 43, 77);
  }
}
