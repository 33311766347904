.hover-dropdown {
    position: relative;
    ul {
      display: none;
      position: absolute;
    }
    &:hover {
      ul {
        display: flex;
        flex-direction: column;
        min-width: 125px;
      }
    }
  }
  