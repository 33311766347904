html,
body {
  height: 100%;
  margin: 0px;
  font-family: "Lato", sans-serif;
  scroll-behavior: smooth;
  overflow-x: hidden;
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
q {
  quotes: "\201C""\201D""\2018""\2019";
  padding: 10px 20px;
  line-height: 1.4;
}

q:before {
  content: open-quote;
  display: inline;
  line-height: 0;
  left: -56%;
  position: relative;
  top: 53px;
  font-size: 60px;
  font-weight: 600;
}
q:after {
  content: close-quote;
  display: inline;
  line-height: 0;
  left: 63%;
  position: relative;
  top: -16px;
  font-size: 60px;
  font-weight: 600;
}
.btn {
  cursor: pointer;
}
.app {
  &-wrapper {
    padding-top: 4.3rem;
  }
}
q,
blockquote {
  quotes: "“" "”" "‘" "’";
  font-style: italic;
}

q:before,
blockquote:before {
  content: open-quote;
}

q:after,
blockquote:after {
  content: close-quote;
}

q:before,
q:after,
blockquote:before,
blockquote:after {
  color: #ffff;
  font-size: 3rem;
}
.subtext-banner {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #666666;
}

//text
.text-5\.5 {
  font-size: 1.375rem;
}

//line-height
.leading-7\/4 {
  line-height: 175%;
}

//height

.h-315 {
  height: 315px;
}

.h-75 {
  height: 75%;
}
.h-7\/10{
  height: 70%;
}

.h-4\/5vh{
  height: 80vh;
}

.h-fitcontent {
  height: fit-content;
}

//
.ml-7\.5 {
  margin-left: 1.875rem;
}

.min-w-10 {
  min-width: 2.5rem;
}

.mx--15px {
  margin-left: -15px;
  margin-right: -15px;
}

//padding

.px-15px {
  padding-left: 15px;
  padding-right: 15px;
}

.py-15\.5 {
  padding-top: 3.875rem;
  padding-bottom: 3.875rem;
}

.py-2\.5 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.pt-11 {
  padding-top: 2.75rem;
}

.pt-12\.5 {
  padding-top: 3.125rem;
}

.pb-3\.5 {
  padding-bottom: 14px;
}

.border-yl-second{
  border-color: #eba142;
}

.border-yl-primary {
  border-color: #f59d3a;
}

.border-gray-de{
  border-color: #dedede;
}
//border-radius

.rounded-4 {
  border-radius: 1rem;
}

//box-shadow
.shadow-banner {
  box-shadow: 8px 8px 16px rgba(200, 200, 200, 0.25), 0px 0px 12px rgba(99, 99, 99, 0.25);
}

//position

//flex

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

//gap
.tooltip {
  position: relative;
  width: max-content;
}

.tooltip .tooltiptext {
  visibility: hidden;
  padding: 0 4px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  transform: translateX(-30%);
  opacity: 0;
  min-width: 240px;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
//bg-color

.bg-yl-primary {
  background: #f59d3a;
}

.bg-blue-secondary {
  background: #2c9cdb;
}

.bg-green-secondary {
  background: #8bc95e;
}

.bg-contact-us {
  background: rgba(245, 157, 58, 0.05);
}
//color

.color-gray-secondary {
  color: #dedede;
}

.color-blue-secondary {
  color: #2c9cdb;
}

.color-green-secondary {
  color: #8bc95e;
}

.boerder-button-yl {
  border-color: #f59d3a;
}

.color-grey-666 {
  color: #666666;
}

.color-grey-999 {
  color: #999999;
}

.color-grey-464646 {
  color: #464646;
}

.color-yl-primary {
  color: #f59d3a;
}

.top-full{
  top: 100%;
}

.max-w-1440{
  max-width: 1440px;
}

@media (min-width: 768px) {

  .md\:py-13px {
    padding-top: 13px;
    padding-bottom: 13px;
  }

  .md\:h-7\/10{
    height: 70%;
  }

  .md\:mb-56 {
    margin-bottom: 14rem;
  }

  .md\:py-56 {
    padding-top: 14rem;
    padding-bottom: 14rem;
  }

  .md\:px-27\.5 {
    padding-left: 6.875rem;
    padding-right: 6.875rem;
  }

  .md\:px-12\.5 {
    gap: 3.125rem;
  }

  .md\:gap-11 {
    gap: 2.75rem;
  }

  .md\:mb-11 {
    margin-bottom: 2.75rem;
  }

  .md\:mb-16 {
    margin-bottom: 4rem;
  }
}

.list-stye-type-cir{
  list-style-type: disc;
  list-style-position: inside;
}

@media (min-width: 1024px) {
  .lg\:mw-150{
    max-width: 150px;
  }

  .lg\:w-82{
    width: 20.5rem !important;
  }

  .lg\:h-screen-101{
    height: 101vh;
  }
  .container {
    max-width: 1330px;
  }
  .bottom-2\/5 {
    bottom: 40%;
  }

  .lg\:text-heading1 {
    font-size: 44px;
    line-height: 53px;
    letter-spacing: 1px;
  }

  .lg\:text-heading3 {
    font-size: 24px;
    line-height: 29px;
    text-transform: capitalize;
    color: #fbfbfb;
  }

  .lg\:text-decripton {
    font-size: 20px;
    line-height: 150%;
  }

  .lg\:leading-4\.75 {
    line-height: 1.1875rem;
  }

  .lg\:h-33 {
    height: 8.25rem;
  }

  .lg\:w-165\.5 {
    width: 41.375rem;
  }
}

@media (min-width: 1170px) {
  .xl\:w-82{
    width: 20.5rem;
  }
  .xl\:h-13{
    height: 3.25rem;
  }
}
