.proposal {
    .date-container {
        .datepicker {
            top: 100%;
        }

        .datepicker>div {
            width: 100%;
        }

        .react-datepicker {
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            width: 100%;

            &__month-container,
            &__time-container {
                width: 100%;

                &>div {
                    width: 100%;

                    .react-datepicker__time-box {
                        width: 100%;
                        margin: unset;
                    }
                }

                .react-datepicker__header {
                    border-top-left-radius: 8px;
                    border-top-right-radius: 8px;
                }
            }
        }

        .fa-angle-down {
            display: none;
        }
    }
}