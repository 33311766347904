$bassic-gray2-color: #828282;
$text-color: #231f20;
$bassic-line-height: 22px;
.bassic-gray2 {
  color: $bassic-gray2-color;
}

.passport-heading {
  margin-bottom: 58px;
}

.passport-body-description {
  font-size: 16px;
  color: $text-color;
  line-height: 22px;
}

.passport-benefit-item {
  .passport-benefit-img {
    border-radius: 5px;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.passport-text-color {
  color: $text-color;
}

.bassic-line-height {
  line-height: $bassic-line-height;
}

.passport-bassic-text {
  color: $text-color;
  line-height: $bassic-line-height;
}

.passport-choose-reason-des {
  margin-top: 60px;
}

@media (min-width: 1024px) {
  .passport-heading-hongkong {
    line-height: 86px;
  }
}

.passport-title-hongkong {
  margin-top: 60px;
  margin-bottom: 60px;
}

.passport-plan-item-hongkong {
  margin-bottom: 60px;
}

.flex.passport-about-item {
  display: flex !important;
}

.passport-content-items {
  text-align: start;
}

.hongkong-passport-prev-btn {
  transform: rotate(180deg);
}

.hongkong-passport-slide-btn {
  top: 50%;
  transform: translateY(-50%);
}

.hongkong-passport-next-btn {
  right: -20%;
}

.hongkong-passport-prev-btn {
  left: -20%;
}

.passport-hongkong-about-us-avatar {
  width: 120px;
}

.listing-passport-list {
  @media (max-width: 767px) {
    white-space: nowrap;
    display: inline-block;
    width: 100%;
  }
}

.passport-section-space {
  margin-top: 60px;
}
