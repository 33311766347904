.react-tel-input input[type=text], .react-tel-input input[type=tel] {
  width: 100%;
  border-color: #e2e8f0 !important;
  border-radius: 0.5rem;
}

.react-tel-input .flag-dropdown {
  background-color: transparent;
  border-color: #e2e8f0 !important;
  .selected-flag {
    padding: 0 0 0 8px;
    .arrow {
      left: 21px;
    }
  }
}
