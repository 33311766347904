.sticky-header {
  top: auto;
  bottom: 0;
}

@media only screen and (min-width: 1024px) {
  .sticky-header {
    top: 175px;
  }
  .sticky-header-75 {
    top: 75px;
  }
}
