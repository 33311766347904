.options {
  &-cities {
    position: absolute;
    top: 100%;
    z-index: 500;
    left: 0;
  }
  &-categories {
    position: absolute;
    top: 100%;
    z-index: 500;
    left: 0;
  }
  &-city-area-selection {
    position: absolute;
    top: 100%;
    z-index: 500;
    width: max-content;
    min-width: 90%;
  }
  &-capacity {
    position: absolute;
    top: 100%;
    z-index: 500;
    width: max-content;
  }
  &-budget {
    position: absolute;
    top: 100%;
    z-index: 500;
    width: max-content;
    right: 0;
  }
  &-amenities {
    position: absolute;
    top: 100%;
    z-index: 500;
    width: max-content;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    max-width: 55rem;
  }
  &-categories-selection {
    position: absolute;
    top: 100%;
    z-index: 500;
    width: max-content;
  }
  &-search-list {
    position: absolute;
    top: 100%;
    z-index: 500;
    width: max-content;
    right: 0;
    min-width: 25rem;
  }
  &-tags {
    position: absolute;
    top: 100%;
    z-index: 500;
    width: max-content;
    max-width: 18rem;
    right: 0;
    .overflow-auto {
      &::-webkit-scrollbar-track
      {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        background-color: #F5F5F5;
      }
      &::-webkit-scrollbar
      {
        width: 4px;
        background-color: #F5F5F5;
      }
      &::-webkit-scrollbar-thumb
      {
        background-color: #eba142;
        border: 2px solid #eba142;
      }
    }
  }
  &-sort {
    position: absolute;
    top: 100%;
    z-index: 500;
    width: max-content;
    right: 0;
  }
  &-more-categories {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 500;
    width: max-content;
    max-width: 700px;
    .overflow-auto {
      &::-webkit-scrollbar-track
      {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        background-color: #F5F5F5;
      }
      &::-webkit-scrollbar
      {
        width: 4px;
        background-color: #F5F5F5;
      }
      &::-webkit-scrollbar-thumb
      {
        background-color: #eba142;
        border: 2px solid #eba142;
      }
    }
  }
  &-table-actions {
    position: absolute;
    top: 0;
    z-index: 500;
    width: max-content;
    right: 0;
    ul {
      a, li {
        &:hover {
          color: #eba142;
        }
      }
    }
  }
}
