.background-header__size {
  height: 550px;
}
.passport-background-header__size {
  height: 600px;
}
.background-header__size--about {
  height: auto;
}
.resource-center-wrapper {
  .subheader-text {
    font-size: 1.375rem;
  }
  .resource-card {
    box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
    border-bottom: 4px solid #eba142;
  }
}
.resource-header.background-header__size {
  height: auto;
}
.btn-border--orange {
  border-bottom: 4px solid #eba142;
  &:focus {
    outline: none;
  }
}
.btn-border--gray {
  border-bottom: 4px solid #eeecec;
  &:focus {
    outline: none;
  }
}
.category-featured-space {
  cursor: pointer;
  .category-grid-item {
    transition: all 0.3s ease 0s;
    height: 20rem;
    z-index: 1;
    margin: 1.5%;
    .item-overlay {
      background: rgba(0, 0, 0, 0.3);
    }
  }
  .category-grid-title {
    transition: all 0.3s ease;
    top: 0;
    &:hover {
      opacity: 0;
    }
  }
  .category-grid-icon {
    transition: all 0.3s ease;
    &:hover {
      opacity: 0.1;
    }
  }
  .item > div:hover .item-overlay {
    opacity: 1;
    color: #fff;
  }
}
.bg-box {
  background: rgba(0, 0, 0, 0.3);
  padding-top: 9rem;
}
.coworking-space-wrapper,
.event-space-wrapper,
.serviced-office-wrapper,
.meeting-room-wrapper,
.brand-pages-header {
  height: 500px;
}
.get-free__btn {
  &:hover {
    background: #eba142;
    color: #fff;
  }
}
.why-choose-wrapper {
  .why-choose__text {
    font-size: 2.4rem;
  }
  .why-more__text {
    font-size: 1.9rem;
  }
}
.resource-card {
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
  border-bottom: 4px solid #eba142;
}
.terms-wrapper {
  .terms--lettered {
    list-style-type: upper-alpha;
    counter-reset: number;
    > li {
      &::before {
        content: counter(number) '. ';
        counter-increment: number;
        margin-right: 2px;
      }
    }
  }
  .terms--alpha {
    list-style-type: upper-alpha;
    counter-reset: letter;
    > li {
      &::before {
        content: counter(letter, upper-alpha) '.';
        counter-increment: letter;
        margin-right: 10px;
      }
    }
  }
}
.cancellation-wrapper {
  .cancellation--lettered {
    list-style-type: upper-alpha;
    counter-reset: letter;
    > li {
      &::before {
        content: counter(letter, lower-alpha) '.';
        counter-increment: letter;
        margin-right: 10px;
      }
    }
  }
}
.box-shadow {
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
}

.passport-wrapper {
  .explore-passport-wrapper {
    .passport-city {
      cursor: pointer;
      .city-overlay {
        background-color: rgba(0, 0, 0, 0.3);
        &:hover {
          background-color: transparent;
        }
      }
      .passport-text {
        top: 51%;
      }
      .passport-city-img {
        a {
          &:hover {
            p {
              display: none;
            }
          }
        }
      }
    }
  }
}
.passport-location-wrapper {
  .passport-featured-wrapper {
    .passport-featured-grid {
      a {
        .passport-featured-item {
          width: 290px;
          height: 290px;
          z-index: 1;

          .passport-grid-image {
            top: 0;
            left: 0;
          }
          .passport-grid-content {
            &:hover {
              opacity: 0;
            }
            .passport-grid-icon {
              background: rgba(0, 0, 0, 0.4);
              transition: all 0.3s ease;
              &:hover {
                background: transparent;
              }
            }
            .passport-grid-title {
              bottom: 0;
              left: 0;
              transition: all 0.3s ease;
            }
          }
          .passport-space-grid-item {
            &:hover {
              opacity: 0.8;
            }
          }
        }
      }
    }
  }
}
.passport-plan-wrapper {
  .passport-plan-item-wrapper {
    height: max-content;
    min-height: 750px;

    .passport-plan-item {
      transition: all 0.3s ease;
      .passport-plan-action-wrapper {
        width: 100%;
        padding-top: 20px;
      }
      .passport-plan-price {
        sup {
          top: -12px;
          font-size: 42%;
          margin-right: 12px;
        }
      }
      .passport-plan-description {
        li {
          &::before {
            content: '';
            width: 8px;
            height: 8px;
            border-radius: 100%;
            background: #999999;
            position: absolute;
            top: 9px;
            left: -18px;
            transition: all 0.3s ease;
          }
          .passport-plan-text {
            font-size: 18px;
          }
        }
      }
      .passport-plan-form {
        .form-control {
          font-size: 17px;
          font-weight: 300;
          border: none;
          border-bottom: 1px solid #999999;
          outline: none;
          box-shadow: none;
          transition: all 0.3s ease;
          padding-top: 3px;
          padding-bottom: 4px;
          height: auto;
        }
        select.form-control {
          -webkit-appearance: none;
          background: transparent;
          border-radius: 0;
        }
      }
    }
    .starter {
      &:hover {
        border-color: #eba142;
      }
    }
    .passport-plan-item:hover.starter .passport-plan-title {
      color: #eba142;
    }
    .passport-plan-item:hover.unlimited .passport-plan-title {
      color: #2f9cd7;
    }
    .passport-plan-item:hover.personalized .passport-plan-title {
      color: #8bc65d;
    }
    .passport-plan-item:hover .passport-plan-price,
    .passport-plan-item:hover .passport-plan-subtitle,
    .passport-plan-item:hover .passport-plan-text {
      color: #5a5c57;
    }
    .passport-plan-item:hover.starter .passport-plan-description li::before {
      background: #eba142;
    }
    .passport-plan-item:hover.unlimited .passport-plan-description li::before {
      background: #2f9cd7;
    }
    .passport-plan-item:hover.starter .passport-plan-action-btn {
      background: #eba142;
      border-color: #eba142;
    }
    .passport-plan-item:hover.unlimited .passport-plan-action-btn {
      background: #2f9cd7;
      border-color: #2f9cd7;
    }
    .passport-plan-item:hover.personalized .passport-plan-action-btn {
      background: #8bc65d;
      border-color: #8bc65d;
    }
    .unlimited {
      &:hover {
        border-color: #2f9cd7;
      }
    }
    .personalized {
      &:hover {
        border-color: #8bc65d;
      }
    }
  }
}
.featured-spaces__carousel__item__wrapper {
  .featured-spaces__carousel__item {
    .featured-spaces__carousel__item__sash {
      display: block;
      width: 160px;
      padding: 4px 0;
      background: #eba142;
      color: #fff;
      font-size: 16px;
      font-weight: 600;
      text-transform: uppercase;
      position: absolute;
      top: 28px;
      text-align: center;
      right: -34px;
      transform: rotate(45deg);
      z-index: 2;
    }
    .featured-spaces__carousel__item__image {
      height: 350px;
      overflow: hidden;
      border-radius: 6px;
      position: relative;
      z-index: 1;
      .featured-spaces__carousel__item__image__wrapper {
        width: 100%;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .featured-spaces__carousel__item__action {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      padding: 24px;
      justify-content: center;
      align-items: center;
      background: rgba(0, 0, 0, 0.3);
      opacity: 0;
      transition: opacity 0.3s ease;
      a {
        display: block;
        color: #fff;
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
        padding: 6px 24px;
        border: 2px solid #fff;
        border-radius: 6px;
      }
    }
    .featured-spaces__carousel__item__description {
      text-align: left;
      .featured-spaces__carousel__item__merchant-details {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 18px;
        margin-bottom: 8px;
      }
      p {
        margin: 0;
        font-size: 16px;
        font-weight: 300;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:first-child {
          color: #eba142;
          font-weight: 600;
          text-transform: uppercase;
          margin-right: 12px;
        }
      }
    }
  }
}
.passporthome-section {
  .passporthome-wrapper {
    margin: 60px 0;
    padding: 60px 0;
    .passporthome-content {
      .get-started__btn {
        &:hover {
          background-color: #eba142;
          color: #fff;
        }
      }
    }
  }
}
.semi-banner-wrapper {
  height: 600px;
  .semi-banner-content {
    .semi-header {
      background: rgba(28, 183, 153, 0.8) none repeat scroll 0% 0%;
    }
    .semi-text {
      background: rgba(255, 255, 255, 0.8) none repeat scroll 0% 0%;
    }
  }
}
.passport-pay__plan-details__banner__overlay {
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1;
}
.passport-pay__plan-details__banner__text {
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}
.passport-pay__payment-details__table {
  padding-bottom: 3px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.3);
}
.client-testimonial-wrapper .client-images {
  height: 310px;
  width: 310px;
}
.about-content-wrapper {
  .about-map {
    height: 400px;
    width: 400px;
  }
}
.howdoesitwork-wrapper {
  .howitwork-image {
    margin-bottom: 30px;
    max-width: 185px;
  }
}
.venue-profile {
  .venue-image {
    max-width: 22rem;
  }
}
.category-wrapper {
  p {
    line-height: 1.666;
  }
  .why-choose-wrapper {
    .why-choose-content {
      .category-text {
        font-size: 1.6rem;
        line-height: 1.2;
      }
    }
  }
}
.case-study-carousel {
  .case-study-wrapper {
    .case-bg-box {
      -webkit-clip-path: polygon(0 112%, 0 100%, 0 -153%, 172% 100%);
      clip-path: polygon(0 112%, 0 100%, 0 -153%, 172% 100%);
      width: auto;
      height: auto;
      background-color: rgba(0, 0, 0, 0.56);
      .case-content {
        .case-study--carousel {
          margin-left: 6%;
          margin-right: 6%;
        }
      }
    }
  }
}
.case-study-wrapper.background-header__size {
  display: inline-flex;
}
.download-hover {
  &:hover {
    color: #eba142;
  }
}
.explore-div {
  .get-started__btn {
    &:hover {
      background-color: #eba142;
      color: #fff;
    }
  }
}

.invert-image-color {
  filter: brightness(0.5);
}

.max-h-working-space-city-page {
  max-height: 375px;
  overflow: hidden;
}

/* media queries */

@media screen and (min-width: 992px) {
  .case-study-carousel {
    .case-study-wrapper {
      height: 450px;
      .case-bg-box {
        -webkit-clip-path: polygon(0 112%, 0 100%, 0 -136%, 89% 100%);
        clip-path: polygon(0 112%, 0 100%, 0 -136%, 89% 100%);
        width: 900px;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.56);
        .case-content {
          .case-study--carousel {
            margin-left: 8%;
          }
        }
      }
    }
  }
  .case-study-wrapper.background-header__size {
    height: 550px;
    display: inline-flex;
  }
  .resource-header.background-header__size {
    height: 550px;
  }
}

@media screen and (min-width: 2000px) {
  .case-study-carousel {
    .case-study-wrapper {
      height: 600px;
      .case-bg-box {
        -webkit-clip-path: polygon(0 112%, 0 100%, 0 -136%, 89% 100%);
        clip-path: polygon(0 112%, 0 100%, 0 -136%, 89% 100%);
        width: 1400px;
        height: 650px;
        background-color: rgba(0, 0, 0, 0.56);
        .case-content {
          .case-study--carousel {
            margin-left: 8%;
          }
        }
      }
    }
  }
  .case-study-wrapper.background-header__size {
    height: 550px;
    display: inline-flex;
  }
  .resource-header.background-header__size {
    height: 550px;
  }
}
@media screen and (min-width: 1800px) {
  .text-ct {
    font-size: 14px;
  }
}

.traditional-heading {
  &.permonth {
    font-size: 24px;
  }
  &.persquare {
    font-size: 18px;
  }
  &.date {
    font-size: 18px;
  }
  &.address {
    font-size: 16px;
    line-height: 20px;
  }
}

@media (max-width: 1023px) {
  .traditional-heading {
    &.address {
      font-size: 14px;
      line-height: 20px;
    }
  }
} ;
