@import 'index.scss';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
@import 'base/override';
@import 'base/slick';
@import 'components/expert';
@import 'components/grid';
@import 'components/lightbox';
@import 'components/slider';
@import 'components/dropdown';
@import 'components/speechBubble';
@import 'components/expert';
@import 'components/buildingPage';
@import 'base/clientpages';
@import 'components/showmore';
@import 'components/passport';
@import 'components/commercial-building';
@import 'components/proposal';
@import 'components/review';
