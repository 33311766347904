//override css for slick

@media screen and (min-width: 1024px) {
  .slick-slider {
    &:not(.no-custom) {
      &.space-card {
        .slick-dots {
          li {
            margin: 0;
            width: 16px;
            height: 16px;
            &.slick-active {
              button::before {
                color: #eba142;
              }
            }
            button::before {
              color: white;
              opacity: 1;
            }
          }
        }
      }

      .slick-track {
        position: relative;
        top: 0;
        left: 0;
        display: flex;
        margin-left: auto;
        margin-right: auto;
        align-items: center;
      }
      .slick-arrow.slick-prev,
      .slick-arrow.slick-next {
        visibility: hidden;
      }
      .slick-slide img {
        vertical-align: middle;
        display: inline-block;
      }
      .slick-initialized .slick-slide {
        display: inline-block;
      }
      .slick-slide {
        text-align: center;
        float: none;
      }

      .slick-list .slick-track .slick-slide {
        height: 100%;
        & > div {
          height: 100%;
        }
      }

      .slick-list {
        height: 100%;
        & > div {
          height: 100%;
        }
      }
    }
  }

  #hero-slider {
    height: 100%;
    & > div {
      height: 100%;
    }
  }

  .passport-wrapper .slick-slide {
    width: 140px !important; //need to use important to override the slick inline-style
    margin-right: 60px;
  }
  .category-carousel .slick-prev,
  .category-carousel .slick-next {
    top: 40%;
  }
  .category-carousel .slick-prev {
    left: -25px;
    z-index: 99;
  }
  .category-carousel .slick-next {
    right: 15px;
  }
  .category-carousel .slick-prev:before,
  .category-carousel .slick-next:before {
    opacity: 1;
    color: #fff;
    font-size: 50px;
  }
  #category-carousel .slick-arrow.slick-prev,
  #category-carousel .slick-arrow.slick-next {
    visibility: visible;
  }
  .category-carousel .slick-arrow {
    border-radius: 100%;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.6);
    position: absolute;
    width: 43px;
    height: 43px;
    background: #fff;
  }
  .category-carousel .slick-prev::before {
    content: '';
    position: absolute;
    top: 12px;
    width: 15px;
    height: 15px;
    border-top: solid 2px #333;
    border-right: solid 2px #333;
    right: 9px;
    transform: rotate(-142deg);
  }
  .category-carousel .slick-next::before {
    content: '';
    position: absolute;
    top: 15px;
    width: 15px;
    height: 15px;
    border-top: solid 2px #333;
    border-right: solid 2px #333;
    right: 15px;
    transform: rotate(45deg);
  }
  .category-carousel .slick-prev:hover,
  .category-carousel .slick-prev:focus,
  .category-carousel .slick-next:hover,
  .category-carousel .slick-next:focus {
    color: #fff;
    outline: none;
    background: #fff;
  }

  .semi-banner-wrapper {
    height: 400px;
  }
}
.space-profile:hover .slick-slider .icon .icon-next,
.space-profile:hover .slick-slider .icon .icon-prev {
  visibility: visible;
}
.stretch-list {
  .slick-slider {
    width: 100%;
    & > .slick-list {
      height: 100%;
      & > .slick-track {
        height: 100%;
        display: flex;
        align-items: stretch;
        .slick-slide {
          display: flex;
          height: auto;
          flex-direction: column;
          & > div {
            height: 100%;
          }
        }
      }
    }
  }
}

.partner-list .slick-track {
  margin: 0;
}

.slick-initialized{
  overflow-x: hidden;
}
