@font-face {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lato/v17/S6u9w4BMUTPHh7USSwaPGQ3q5d0N7w.woff2)
    format('woff2');
}
/* latin */
@font-face {
  src: url(https://fonts.gstatic.com/s/lato/v17/S6u9w4BMUTPHh7USSwiPGQ3q5d0.woff2) format('woff2');
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}
/* latin-ext */
@font-face {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lato/v17/S6uyw4BMUTPHjxAwXiWtFCfQ7A.woff2) format('woff2');
}
/* latin */
@font-face {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lato/v17/S6uyw4BMUTPHjx4wXiWtFCc.woff2) format('woff2');
}
/* latin-ext */
@font-face {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lato/v17/S6u9w4BMUTPHh6UVSwaPGQ3q5d0N7w.woff2)
    format('woff2');
}
/* latin */
@font-face {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lato/v17/S6u9w4BMUTPHh6UVSwiPGQ3q5d0.woff2) format('woff2');
}

@font-face {
  font-family: 'proxima_nova_altbold';
  src: url('~assets/fonts/proxima-nova/pn-alt-bold.eot');
  src: url('~assets/fonts/proxima-nova/pn-alt-bold.eot?#iefix') format('embedded-opentype'),
    url('~assets/fonts/proxima-nova/pn-alt-bold.woff2') format('woff2'),
    url('~assets/fonts/proxima-nova/pn-alt-bold.woff') format('woff'),
    url('~assets/fonts/proxima-nova/pn-alt-bold.svg#proxima_nova_altbold') format('svg');
  font-weight: normal;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'proxima_nova';
  src: url('~assets/fonts/proxima-nova/pn-alt-reg.eot');
  src: url('~assets/fonts/proxima-nova/pn-alt-reg.eot?#iefix') format('embedded-opentype'),
    url('~assets/fonts/proxima-nova/pn-alt-reg.woff') format('woff'),
    url('~assets/fonts/proxima-nova/pn-alt-reg.svg#proxima_nova_altregular') format('svg');
  font-weight: normal;
  font-display: swap;
  font-style: normal;
}
