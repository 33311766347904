$ph-bg:                   #edf2f7 !default;
$ph-color:                #e2e8f0 !default;
$ph-border-radius:        2px !default;

$ph-gutter:               30px !default;
$ph-spacer:               15px !default;

$ph-avatar-border-radius: 50% !default;

$ph-animation-duration:   .9s !default;
