$button-color: #c4c4c4;

.inquire-building-form {
  .building-inquiry-avt-container {
    width: 101px;
    height: 101px;
    border-radius: 50%;
    overflow: hidden;
  }

  .contactInfoItem {
    width: 132px;
    text-align: center;
    padding: 5px;
    margin: 5px 0;
  }
}

.dropdown-group {
  position: relative;

  .three-dots {
    &:after {
      cursor: pointer;
      color: $button-color;
      content: '\2807';
      font-size: 26px;
      width: 100%;
      height: 100%;
    }
  }

  .dropdown-list {
    display: none;
    position: absolute;
    right: 5px;
    top: calc(100% + 5px);
    background-color: white;
    box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.23);
    transition: opacity 0.1s, z-index 0.1s, max-height 5s;
    border-radius: 2px;

    .dropdown-item {
      width: max-content;
      height: max-content;
      z-index: 1;
    }
  }

  #selectDropdownMenu:checked {
    ~ .dropdown-list {
      display: block;
    }
  }
}

.building-client-page-heading {
  @media (min-width: 1024px) {
    font-size: 40px;
    line-height: 48px;
  }
  font-size:36px;
  line-height: 44px;
  position: relative;
  font-weight: normal;
  color: #000000;
}

.building-amenities {
  background-color: rgba(242, 201, 76, 0.2);
  border-radius: 5px;
}

.building-heading-border {
  border: 1px solid #e0e0e0;
  border-radius: 5px;
}

.building-heading-border-top {
  border-top: 1px solid #e0e0e0;
}

.building-heading-gray-text {
  color: #828282;
}

.building-separate {
  border-top: 1px solid #e4e4e4;
}

.building-description {
  font-size: 16px;
  line-height: 22px;
  p {
    font-size: 16px;
    line-height: 22px;
  }
}

.building-page-content {
  max-height: 40vh;
  overflow-y: auto;
  overflow-x: hidden;
}
