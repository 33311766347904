@media only screen and (min-width: 1024px) {
	.speech-bubble {
		position: relative;
		background: #fff0d6;
	}

	.speech-bubble:after {
		content: '';
		position: absolute;
		left: 0;
		top: 50%;
		width: 0;
		height: 0;
		border: 20px solid transparent;
		border-right-color: #fff0d6;
		border-left: 0;
		margin-top: -20px;
		margin-left: -20px;
	}
} 

.speech-bubble {
	background: #fff0d6;
}