.slick-slide div[style] {
  &:focus {
    outline: none;
  }
}

// This will remove navbar shadow on space/venue/passport page
// so it won't conflict with navbar-sticky
.remove-nabar-shadow {
  .navbar {
    box-shadow: none;
  }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// This will minimize the text beside the flag in the phone input field
.intl-tel-input.allow-dropdown.separate-dial-code .selected-dial-code {
  font-size: 0.85rem !important;
  z-index: -1;
  top: 13px;
  left: 9px;
  position: absolute;
}

// override the country list dropdown of contact
.intl-tel-input .country-list {
  width: 100%;
}

.bordered-table {
  th,
  td {
    border-width: 1px;
  }
}
