.react-datepicker-removed-border-version {
  border: 0 !important;
}

.react-datepicker .react-datepicker__time-list {
  &::-webkit-scrollbar-track
  {
    box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
  }
  &::-webkit-scrollbar
  {
    width: 4px;
    background-color: #F5F5F5;
  }
  &::-webkit-scrollbar-thumb
  {
    background-color: #eba142;
    border: 2px solid #eba142;
  }
}
