.modal-open {
    overflow: hidden !important;

    .modal {
        overflow-x: hidden;
        overflow-y: auto;
        outline: 0;
        z-index: 1040;
    }

    .modal-container {
        position: relative;
        width: auto;
        margin: .5rem;
        z-index: 1050;
        min-height: calc(100% - (.5rem * 2));
        display: flex;
        align-items: center;
        box-shadow: none;
        flex-direction: column;
        justify-content: center;

        .modal-content-con {
            border: 1px solid #eee;
        }

        &.modal-container-gallery {
            width: auto;
            margin: 0 auto;
            display: block;
            min-height: 1px;
        }

        @media (min-width: 576px) {
            max-width: 500px;
            margin: 1.75rem auto;
            min-height: calc(100% - (1.75rem * 2));

            &.modal-container-gallery {
                max-width: 100%;
                margin: 0 auto;
                display: block;
                min-height: 1px;
            }
        }
    }

    &.gallery-modal {
        .modal-container {
            position: relative;
            width: auto;
            margin: 0 auto;
            z-index: 1050;
            min-height: 1px;
            display: block;
            align-items: center;
            background: #fff;

            @media (min-width: 576px) {
                max-width: 100%;
                margin: 0 auto;
                min-height: 1px;
            }
        }
    }
}