.slider {
  &-img {
    height: 650px;
  }
}

.city-page-wrapper {
  .slick-dots {
    bottom: 0px;
    li button:before {
      font-size: 12px;
    }
  }
}
