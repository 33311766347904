.bg-thread {
  background-color: #f2f2f2;
}
.max-w-75 {
  max-width: 75%;
}
.bg-orange-150 {
  background-color: #fff4de;
}
.min-w_10 {
  min-width: 2.5rem;
}
.max-w_10 {
  max-width: 2.5rem;
}
.align-middle {
  vertical-align: middle;
}
.m_5 {
  margin: 5%;
}
.bg-teal-150 {
  background-color: #bdf2e9;
}
.w_15 {
  width: 15%;
}
.w_85 {
  width: 85%;
}
.w_28 {
  width: 28%;
}
.w_70 {
  width: 70%;
}
.w_6 {
  width: 6%;
}
@media (min-width: 1024px) {
  .h-vh-msg {
    height: 84vh;
  }
}
@media (max-width: 1023px) {
  .h-vh-msg {
    max-height: 60px;
  }
}
.min-h-vh-msg {
  min-height: 84vh;
}
@media (max-width: 1023px) {
  .min-h-vh-msg {
    overflow-y: auto;
  }
}
.w_14 {
  width: 14%;
}
.w_86 {
  width: 86%;
}
.bg-gray-50 {
  background-color: #fcfcfc;
}
.right-6 {
  right: 6px;
}
.top-px-10 {
  top: 10px;
}
.px_3 {
  padding-left: 3%;
  padding-right: 3%;
}
.h-vh-90 {
  height: 90vh;
}

@media (max-width: 1023px) {
  .threads-container {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
  }
  .conversation-messages {
    height: 40vh;
  }
  .message-box-area {
    max-height: 10vh;
    overflow-y: auto;
    font-size: 12px;
  }
}

.attachment-container {
  max-height: 30vh;
  overflow-y: auto;
}

.popup-remind{
  z-index: 1;
  top: 100%;
  left: 0;
  width: 60%;
  max-width: 450px;
  button{
    height: 40px;
    width: 170px;
    border-radius: 4px;
    font-size: 14px;
    line-height: 17px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    border-color: #e0a659;
  }

  p{
    color: #363636;
  }
}