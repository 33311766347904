.grid {
  display: grid;
  grid-template-columns: repeat(3, [col-start] 1fr);
  grid-template-rows: repeat(2, [col-start] 1fr);
  grid-template-areas:
    'featured featured top'
    'featured featured bottom';
  grid-gap: 5px;
  &.override {
    grid-template-columns: auto;
    grid-template-rows: repeat(1, [col-start] 1fr);
    grid-template-areas: 'featured featured';
    &.single {
      grid-template-areas: 'featured';
    }
  }
  .image-container {
    img {
      height: 572px;
      width: 100%;
      object-fit: cover;
    }
  }
  .image-container-multiple:first-child {
    grid-area: featured;
    video,
    img {
      height: 572px;
      width: 100%;
      object-fit: cover;
    }
  }
  .image-container-multiple:not(:first-child) {
    video,
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      max-height: 283px;
    }
  }

  @media only screen and (max-width: 639px) {
    display: block;

    > div:first-child {
      display: block;
      img {
        height: 400px;
      }
    }
    div:not(:first-child) {
      display: none;
    }
  }

  @media only screen and (max-width: 414px) {
    > div:first-child {
      img {
        height: 350px;
      }
    }
  }
}
@media only screen and (max-width: 480px) {
  .image-container-multiple:first-child {
    img {
      height: auto !important;
    }
  }
}
