.my-custom-form {
  label {
    font-weight: 600;
    font-size: 20px;
    line-height: 100%;
    color: #666666;
    width: 100px;
    display: inline-block;
  }

  .required {
    font-weight: 600;
    font-size: 24px;
    line-height: 100%;
    color: #ed8f8f;
    margin-left: 4px;
  }

  button[type='submit'] {
    background: #f59d3a;
    color: #fff;
    border-radius: 40px;
    padding: 12px 145px;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
  }

  .isrequired::after {
    content: '*';
    font-weight: 600;
    font-size: 24px;
    line-height: 100%;
    color: #ed8f8f;
    margin-left: 4px;
  }

  .PhoneInput {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    &Input {
      font-weight: 600;
      font-size: 18px;
      line-height: 150%;
      flex: 1;
      width: 100%;
    }

    &Country {
      position: relative;
      background-color: #fefaf6;
      &Icon {
        width: 24px;
        position: absolute;
        right: 25px;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        &Img {
          border-radius: 4px;
        }
      }

      &Select {
        background-color: #fefaf6;
        height: 100%;
        width: 60px;
        font-size: 0;
        display: flex;
      }
    }
  }

  .list-option-spacetype,
  .list-option-floor {
    background: #ffffff;
    box-shadow: 0px 0px 16px rgba(136, 136, 136, 0.12);
    border-radius: 8px;
    padding: 0 24px;
    position: absolute;
    z-index: 10;
    top: calc(100% + 5px);
    transition: all 0.4s ease-in-out;
    display: none;

    &.active {
      display: block;
    }

    li {
      font-weight: 600;
      font-size: 16px;
      line-height: 100%;
      color: #333333;
      padding: 16px 0;
      &:not(:last-child) {
        border-bottom: 1px solid #eeeeee;
      }
    }
  }

  .intl-tel-input {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;

    input{
      font-weight: 600;
    }

    &.allow-dropdown {
      .flag-container {
        right: 0 !important;
        left: unset !important;
        width: auto !important;

        .country-list {
          width: 300px !important;
          right: 0 !important;
        }
        .selected-flag {
          background-color: transparent;

          .selected-dial-code {
            top: 5px !important;
            left: unset !important;
          }


        }
      }
      input {
        width: 80%;
        padding-left: 0px !important;
      }
    }
  }
}

@media (max-width: 576px) {
  .my-custom-form {
    button[type='submit'] {
      padding: 12px 35px;
      font-size: 20px;
    }
  }
}

@media (min-width: 1024px) {
  .my-custom-form .PhoneInputInput {
    font-size: 20px;
  }
}
