.slider-container {
  background: #fafafa;
  height: auto;

  .slick-slider {
    height: 100%;
  }

  div {
    overflow: visible;
  }

  .slick-initialized {
    overflow-x: visible;
  }

  .slick-item {
    display: flex !important;
    flex-direction: column-reverse;
    align-items: center;
    height: 100%;
    &-content {
      flex: 3;
    }

    .logo{
      width: 40%;
      text-align: right;
      &>img{
        max-height: 66px;
      }
    }

    &-img {
      flex: 2;
      position: relative;

      img {
        object-fit: cover;
        max-height: 525px;
        @media (max-width: 768px) {
          max-height: 400px;
        }
      }
    }
  }

  .slick-dots {
    display: flex !important;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    background: #f0f0f0;
    width: fit-content;
    height: 6px;
    bottom: -122px;
    left: 70px;

    li {
      width: 32px;
      height: 6px;
      margin: 0 !important;
      &.slick-active {
        background: #d9d9d9;
      }

      button {
        width: 32px;
        height: 6px;
        &::before {
          content: '';
          width: 32px;
          height: 6px;
        }
      }
    }
  }
}

@media (min-width: 1024px) {
  .slider-container {
    height: 400px;

    .slick-item {
      flex-direction: row;
        align-items: unset;
      &-img {
        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 477px;
          height: 525px;
        }
      }
    }
  }
}
